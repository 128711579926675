<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input
        v-model="listQuery.deviceNo"
        placeholder="请输入设备编号"
        style="width: 200px"
        class="filter-item"
        clearable
      />
      <el-select
        v-model="listQuery.typeId"
        placeholder="设备类别"
        style="width: 120px"
        class="filter-item"
        clearable
      >
        <el-option
          v-for="item in equipmentOption"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        />
      </el-select>
      <el-select
        v-model="listQuery.storeId"
        placeholder="门店"
        style="width: 120px"
        class="filter-item"
        clearable
      >
        <el-option
          v-for="item in storeOption"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        />
      </el-select>

      <el-select
        v-model="listQuery.controlPlatform"
        placeholder="控制平台"
        style="width: 120px"
        class="filter-item"
        clearable
      >
        <el-option
          v-for="item in INTLLIGENT_CONTROL_PLATFORM"
          :key="item.key"
          :label="item.value"
          :value="item.key"
        />
      </el-select>
      <el-input
        v-model="listQuery.platformKey"
        placeholder="请输入平台设备编号"
        style="width: 200px"
        class="filter-item"
        clearable
      />
      <CascadeRegion
        :isRequired="true"
        :value="listQuery.cascadeRegion"
        @valueFunc="getQueryValueFunc"
        type="queryCascadeRegion"
        :styleRef="{ width: '260px', marginRight: '6px', top: '-4px' }"
      />

      <el-select
        v-model="listQuery.status"
        placeholder="状态"
        style="width: 120px"
        class="filter-item"
        clearable
      >
        <el-option
          v-for="item in INTLLIGENT_STATUS"
          :key="item.key"
          :label="item.value"
          :value="item.key"
        />
      </el-select>
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        @click="handleFilter"
        >搜索</el-button
      >
      <!--      <el-button-->
      <!--        class="filter-item"-->
      <!--        type="primary"-->
      <!--        @click="checkDialogFormVisible(true, {})"-->
      <!--        >新增</el-button-->
      <!--      >-->
      <el-button
        :loading="synEquipmentLoading"
        class="filter-item"
        type="primary"
        @click="synEquipmentFun"
        >同步设备</el-button
      >
    </div>

    <div style="margin-bottom: 5px">智能设备数量:{{ total }}</div>

    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      size="small"
      border
      highlight-current-row
    >
      <el-table-column
        align="center"
        prop="id"
        label="ID"
        width="95"
      ></el-table-column>
      <el-table-column
        label="设备编号"
        width="180"
        align="center"
        prop="devices_num"
      ></el-table-column>
      <el-table-column label="设备类别" width="100" align="center" prop="type">
        <template slot-scope="scope">
          {{ scope.row.type && scope.row.type.name }}
        </template>
      </el-table-column>
      <el-table-column label="状态" width="100" align="center" prop="status">
        <template slot-scope="scope">
          {{ getValue(INTLLIGENT_STATUS, scope.row.status) }}
        </template>
      </el-table-column>
      <el-table-column label="门店" width="100" align="center" prop="store">
        <template slot-scope="scope">
          {{ scope.row.store && scope.row.store.name }}
        </template>
      </el-table-column>
      <el-table-column
        label="控制平台"
        width="100"
        align="center"
        prop="control_platform"
      >
        <template slot-scope="scope">
          {{
            getValue(INTLLIGENT_CONTROL_PLATFORM, scope.row.control_platform)
          }}
        </template>
      </el-table-column>
      <el-table-column prop="imgs" label="图片" width="120px" align="center">
        <template slot-scope="scope">
          <el-image
            :src="scope.row.showImgs.show_url"
            style="width: 100px"
            fit="cover"
          />
        </template>
      </el-table-column>
      <el-table-column
        label="省/市/区/街道"
        width="220"
        align="center"
        prop="name"
      >
        <template slot-scope="scope">
          {{ scope.row.province ? scope.row.province.name : "无" }} /
          {{ scope.row.city ? scope.row.city.name : "无" }} /
          {{ scope.row.area ? scope.row.area.name : "无" }} /
          {{ scope.row.street ? scope.row.street.name : "无" }}
        </template>
      </el-table-column>
      <el-table-column prop="introduction" label="简介" align="center">
      </el-table-column>
      <el-table-column
        prop="nickname"
        label="操作人"
        width="120px"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.row.opt_user && scope.row.opt_user.nickname }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="created_at"
        label="创建时间"
        width="200px"
      >
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.created_at) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="created_at"
        label="修改时间"
        width="200px"
      >
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.updated_at) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="430"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            @click="checkDialogFormVisible(true, scope.row)"
            >编辑</el-button
          >
          <el-popconfirm
            confirm-button-text="好的"
            cancel-button-text="不用了"
            icon="el-icon-info"
            icon-color="red"
            title="确定将该设备上线吗？"
            @confirm="goOnline(scope.row)"
            style="margin: 0 8px;"
          >
            <el-button
              :disabled="[2, 3, 4].includes(scope.row.status)"
              slot="reference"
              type="success"
              size="mini"
              >设备上线</el-button
            >
          </el-popconfirm>
          <el-popover placement="right" width="400" trigger="click">
            <el-skeleton :loading="QRCodeImgLoading" animated>
              <el-image :src="QRCodeImg" />
            </el-skeleton>
            <el-button
              slot="reference"
              type="warning"
              size="mini"
              @click="getQRCode(scope.row)"
              >小程序二维码</el-button
            >
          </el-popover>
          <el-button
            type="primary"
            size="mini"
            @click="checkEquipmentServiceVisible(true, scope.row)"
            style="margin: 0 8px;"
            >设备服务</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <!--    编辑和新增-->
    <el-dialog
      :title="DIALOG_TITLE_TYPE[dialogStatus]"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form
        ref="form"
        :rules="rules"
        :model="form"
        label-position="left"
        label-width="150px"
        style="width: 80%; margin-left:50px;"
      >
        <el-form-item label="设备类别" prop="typeId" v-if="false">
          <el-select
            v-model="form.typeId"
            placeholder="请选择设备类别"
            style="width: 100%"
            class="filter-item"
            clearable
          >
            <el-option
              v-for="item in equipmentOption"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="门店" prop="storeId">
          <el-select
            v-model="form.storeId"
            placeholder="请选择门店"
            style="width: 100%"
            class="filter-item"
            clearable
          >
            <el-option
              v-for="item in storeOption"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="控制平台" prop="controlPlatform" v-if="false">
          <el-select
            v-model="form.controlPlatform"
            placeholder="请选择控制平台"
            style="width: 100%"
            class="filter-item"
            clearable
          >
            <el-option
              v-for="item in INTLLIGENT_CONTROL_PLATFORM"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="控制平台设备编号"
          prop="controlPlatform"
          v-if="false"
        >
          <el-input
            v-model="form.platformKey"
            placeholder="请输入设备编号"
            style="width: 100%"
            class="filter-item"
            clearable
            disabled
          />
        </el-form-item>
        <el-form-item label="图片" prop="name" v-if="false">
          <UploadList
            :typeProps="UPLOAD_IMG_TYPE.DEVICE"
            @valueFunc="getImgsValueFunc"
            paramsType="imgs"
            :model="form.imgs"
            :maxCount="1"
          />
        </el-form-item>
        <el-form-item label="简介" prop="introduction" v-if="false">
          <el-input
            type="textarea"
            v-model="form.introduction"
            placeholder="请输入"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="checkDialogFormVisible(false,{})">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>

    <!--    设备服务-->
    <el-dialog
      title="设备服务"
      :visible.sync="equipmentServiceVisible"
      :close-on-click-modal="false"
      width="50%"
    >
      <EquipmentServiceDialog :deviceInfoRef="equipmentServiceObj" />
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="checkEquipmentServiceVisible(false,{})"
          :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  DIALOG_TITLE_TYPE,
  UPLOAD_IMG_TYPE,
  getValue,
  INTLLIGENT_STATUS,
  INTLLIGENT_CONTROL_PLATFORM
} from "@/utils/enumUtile";
import { formatApiDate, getFileName } from "@/utils/utils";
import {
  getDeviceList,
  createDevice,
  getEquipmentList,
  goOnlineDevice,
  getWxQrcode,
  synchDevices
} from "@/api/equipment";
import { getStoreList } from "@/api/store";
import UploadList from "@/views/components/uploadList.vue";
import CascadeRegion from "@/views/components/cascadeRegion.vue";
import EquipmentServiceDialog from "./components/equipmentServiceDialog.vue";

export default {
  computed: {
    DIALOG_TITLE_TYPE() {
      return DIALOG_TITLE_TYPE;
    },
    formatApiDate() {
      return formatApiDate;
    },
    UPLOAD_IMG_TYPE() {
      return UPLOAD_IMG_TYPE;
    },
    INTLLIGENT_STATUS() {
      return INTLLIGENT_STATUS;
    },
    INTLLIGENT_CONTROL_PLATFORM() {
      return INTLLIGENT_CONTROL_PLATFORM;
    },
    getValue() {
      return getValue;
    }
  },
  components: { CascadeRegion, UploadList, EquipmentServiceDialog },
  filters: {},
  data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        deviceNo: "",
        typeId: "",
        storeId: "",
        controlPlatform: "",
        platformKey: "",
        provinceId: "",
        cityId: "",
        areaId: "",
        streetId: "",
        status: ""
      },
      dialogFormVisible: false, // 新增 or 编辑的弹框
      dialogStatus: "",
      rules: {
        typeId: [
          { required: true, message: "设备不能为空", trigger: "change" }
        ],
        controlPlatform: [
          { required: true, message: "控制平台不能为空", trigger: "change" }
        ],
        platformKey: [
          { required: true, message: "控制平台设备不能为空", trigger: "change" }
        ]
      },
      form: {
        id: "",
        typeId: "",
        controlPlatform: "",
        platformKey: "",
        storeId: "",
        imgs: [],
        introduction: ""
      },
      btnLoading: false,
      editObj: {}, // 修改的对象
      storeOption: [], // 门店选项
      equipmentOption: [], // 设备类别选项
      QRCodeImg: "", // 小程序显示的二维码图
      QRCodeImgLoading: false,
      equipmentServiceVisible: false, // 设备服务的弹框
      equipmentServiceObj: {}, // 设备服务的弹框
      synEquipmentLoading: false // 同步设备
    };
  },
  created() {
    this.getList();
    this.getStoreOption();
    this.getEquipmentOption();
  },
  watch: {},
  methods: {
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    /**
     * 查询用户列表
     */
    getList() {
      this.listLoading = true;
      const form = this.listQuery;
      const params = {
        ...form
      };
      getDeviceList(params)
        .then(response => {
          this.list = response.data.data;
          this.total = response.data.total;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },

    /**
     * 查询门店
     */
    getEquipmentOption() {
      const params = {
        page: 1,
        limit: 9999
      };
      getEquipmentList(params).then(resp => {
        let equipmentOption = [];
        if (resp && resp.data) {
          equipmentOption = resp.data.data;
        }
        this.equipmentOption = equipmentOption;
      });
    },

    /**
     * 查询门店
     */
    getStoreOption() {
      const params = {
        page: 1,
        limit: 9999
      };
      getStoreList(params).then(resp => {
        let storeOption = [];
        if (resp && resp.data) {
          storeOption = resp.data.data;
        }
        this.storeOption = storeOption;
      });
    },

    /**
     * 切换状态
     * @param status
     * @param editObj
     */
    checkDialogFormVisible(status, editObj = {}) {
      this.dialogFormVisible = status;
      this.editObj = editObj;
      this.dialogStatus =
        editObj && Object.keys(editObj).length !== 0 ? "UPDATE" : "CREATE";
      if (editObj && Object.keys(editObj).length !== 0) {
        this.form.id = editObj.id;
        this.form.typeId = editObj.type_id;
        this.form.controlPlatform = editObj.control_platform;
        this.form.platformKey = editObj.platform_key;
        this.form.storeId = editObj.store_id;
        this.form.introduction = editObj.introduction;
        if (editObj.imgs) {
          const attDataArr = [];
          const imgs = editObj.imgs;
          attDataArr.push({
            dbUrl: imgs.url,
            url: imgs.show_url,
            name: getFileName(imgs.url)
          });
          this.form.imgs = attDataArr;
        }
      } else {
        this.form.id = "";
        this.form.category = "";
        this.form.introduction = "";
        this.form.name = "";
        this.form.imgs = [];
        this.$refs.form.resetFields();
      }
    },

    /**
     * 确认添加还是编辑
     */
    saveData() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          const form = this.form;
          const params = {
            ...form
          };
          if (form.imgs && form.imgs.length !== 0) {
            const urlArr = form.imgs.map(obj => obj["dbUrl"]);
            params.imgs = urlArr.join(",");
          }
          this.btnLoading = true;
          createDevice(params)
            .then(() => {
              this.getList();
              this.checkDialogFormVisible(false, {});
              this.$notify({
                title: "成功",
                message: "保存成功",
                type: "success",
                duration: 2000
              });
            })
            .finally(() => {
              this.btnLoading = false;
            });
        }
      });
    },
    /**
     *  获取图片的值
     * @param type
     * @param value
     */
    getImgsValueFunc(type, value) {
      if (type === "imgs") {
        this.form.imgs = value;
      }
    },
    /**
     * 省市区街道的值获取
     * @param type
     * @param resultArr
     */
    getQueryValueFunc(type, resultArr) {
      if (type === "queryCascadeRegion") {
        this.listQuery.cascadeRegion = resultArr;
        if (resultArr && resultArr.length !== 0) {
          this.listQuery.provinceId = resultArr[0];
          this.listQuery.cityId = resultArr[1];
          this.listQuery.areaId = resultArr[2];
          this.listQuery.streetId = resultArr[3];
        } else {
          this.listQuery.provinceId = "";
          this.listQuery.cityId = "";
          this.listQuery.areaId = "";
          this.listQuery.streetId = "";
        }
      }
    },

    /**
     * 设备上线
     * @param row
     */
    goOnline(row) {
      const params = {
        id: row.id
      };
      goOnlineDevice(params).then(resp => {
        if (resp.code === 0) {
          this.$notify({
            title: "成功",
            message: resp.data,
            type: "success",
            duration: 2000
          });
        } else {
          this.$notify({
            title: "提示",
            message: resp.data,
            type: "warning",
            duration: 2000
          });
        }
        this.getList();
      });
    },
    /**
     * 获取小程序二维码
     * @param row
     */
    getQRCode(row) {
      this.QRCodeImgLoading = true;
      const params = {
        id: row.id
      };
      getWxQrcode(params)
        .then(resp => {
          if (resp.code === 0) {
            this.QRCodeImg = resp.data;
          } else {
            this.$notify({
              title: "提示",
              message: "小程序二维码获取失败",
              type: "warning",
              duration: 2000
            });
          }
        })
        .finally(() => {
          this.QRCodeImgLoading = false;
        });
    },

    /**
     * 切换设备服务
     * @param status
     * @param editObj
     */
    checkEquipmentServiceVisible(status, editObj = {}) {
      this.equipmentServiceVisible = status;
      this.equipmentServiceObj = editObj;
    },
    /**
     * 同步设备
     */
    synEquipmentFun() {
      this.synEquipmentLoading = true;
      synchDevices({})
        .then(resp => {
          if (resp.code === 0) {
            this.$notify({
              title: "提示",
              message: "同步完成",
              type: "success",
              duration: 2000
            });
          }
        })
        .finally(() => {
          this.synEquipmentLoading = false;
        });
    }
  }
};
</script>
<style scoped lang="scss"></style>
